import axios from "axios";

export default {
  getTechnologie(produktgruppeId) {
    return axios.get(
      process.env.VUE_APP_AIS_BASE_URL_OLD +
        "/technologie/" +
        produktgruppeId.substring(1)
    );
  },
  getTechnologieen(systemid) {
    return axios.get(
      process.env.VUE_APP_AIS_BASE_URL_OLD +
        "/systems/" +
        systemid.substring(1) +
        "/technologieen/"
    );
  },
  updateTechnologien(technologieen, systemid) {
    return axios
      .post(
        process.env.VUE_APP_AIS_BASE_URL_OLD +
          "/systems/" +
          systemid.substring(1) +
          "/technologieen/",
        technologieen
      )
      .catch(error => {
        throw error.response.data.errorMessage;
      });
  }
};
